:root {
  --background: #181d1bb3;
  --background-blur: ;
  --background-green: #243022b3;
  --basegrey-80: #34384c;
  --body-01-bold-font-family: "DMSans-Bold", Helvetica;
  --body-01-bold-font-size: 20px;
  --body-01-bold-font-style: normal;
  --body-01-bold-font-weight: 700;
  --body-01-bold-letter-spacing: 0px;
  --body-01-bold-line-height: 28px;
  --body-02-bold-font-family: "DMSans-Bold", Helvetica;
  --body-02-bold-font-size: 18px;
  --body-02-bold-font-style: normal;
  --body-02-bold-font-weight: 700;
  --body-02-bold-letter-spacing: 0px;
  --body-02-bold-line-height: 24px;
  --body-02-font-family: "DMSans-Regular", Helvetica;
  --body-02-font-size: 18px;
  --body-02-font-style: normal;
  --body-02-font-weight: 400;
  --body-02-letter-spacing: 0px;
  --body-02-line-height: 28px;
  --body-03-bold-font-family: "DMSans-Bold", Helvetica;
  --body-03-bold-font-size: 16px;
  --body-03-bold-font-style: normal;
  --body-03-bold-font-weight: 700;
  --body-03-bold-letter-spacing: 0px;
  --body-03-bold-line-height: 24px;
  --body-1-font-family: "DMSans-Regular", Helvetica;
  --body-1-font-size: 20px;
  --body-1-font-style: normal;
  --body-1-font-weight: 400;
  --body-1-letter-spacing: -.2px;
  --body-1-line-height: 28px;
  --button-default-font-family: "DMSans-Bold", Helvetica;
  --button-default-font-size: 16px;
  --button-default-font-style: normal;
  --button-default-font-weight: 700;
  --button-default-letter-spacing: 0px;
  --button-default-line-height: 16px;
  --caption-bold-font-family: "DMSans-Bold", Helvetica;
  --caption-bold-font-size: 14px;
  --caption-bold-font-style: normal;
  --caption-bold-font-weight: 700;
  --caption-bold-letter-spacing: 0px;
  --caption-bold-line-height: 24px;
  --critical: #d33535;
  --darkhover: #222630;
  --darkline-dark: #23262f;
  --drop-dark: 0px 64px 64px -48px #0f0f0f1a;
  --drop-section: 0px 14px 64px -48px #0f0f0f1a;
  --headline-1-font-family: "DMSans-Bold", Helvetica;
  --headline-1-font-size: 64px;
  --headline-1-font-style: normal;
  --headline-1-font-weight: 700;
  --headline-1-letter-spacing: -1.28px;
  --headline-1-line-height: 72px;
  --headline-2-font-family: "DMSans-Bold", Helvetica;
  --headline-2-font-size: 48px;
  --headline-2-font-style: normal;
  --headline-2-font-weight: 700;
  --headline-2-letter-spacing: -.96px;
  --headline-2-line-height: 56px;
  --headline-4-font-family: "DMSans-Bold", Helvetica;
  --headline-4-font-size: 32px;
  --headline-4-font-style: normal;
  --headline-4-font-weight: 700;
  --headline-4-letter-spacing: -.32px;
  --headline-4-line-height: 40px;
  --headline-5-font-family: "DMSans-Bold", Helvetica;
  --headline-5-font-size: 24px;
  --headline-5-font-style: normal;
  --headline-5-font-weight: 700;
  --headline-5-letter-spacing: 0px;
  --headline-5-line-height: 32px;
  --on-surface: #23262f;
  --primary: #5ba174;
  --secondary: #777e90;
  --secondary-2: #b1b5c3;
  --success: #58bd7d;
  --surface-main: #fff;
}

.web {
  background-color: #131720;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.web .overlap-wrapper {
  background-color: #131720;
  width: 1728px;
  height: 4987px;
  overflow: hidden;
}

.web .overlap {
  width: 1734px;
  height: 4988px;
  position: relative;
}

.web .background {
  object-fit: cover;
  width: 1728px;
  height: 1713px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .bacckground {
  background-color: #0a0e17;
  width: 1734px;
  height: 3003px;
  position: absolute;
  top: 1713px;
  left: 0;
}

.web .content {
  width: 1728px;
  height: 1725px;
  position: absolute;
  top: 86px;
  left: 0;
}

.web .group {
  width: 402px;
  height: 285px;
  position: absolute;
  top: 1132px;
  left: 1326px;
}

.web .img {
  width: 262px;
  height: 47px;
  position: absolute;
  top: 83px;
  left: 150px;
}

.web .graphic {
  width: 519px;
  height: 574px;
  position: absolute;
  top: 2879px;
  left: 1209px;
}

.web .IMG {
  box-shadow: var(--drop-section);
  width: 985px;
  height: 610px;
  position: absolute;
  top: 2900px;
  left: 701px;
}

.web .div {
  width: 730px;
  height: 355px;
  position: relative;
  top: 185px;
  left: 81px;
}

.web .frame-wrapper {
  width: 1728px;
  height: 4243px;
  position: absolute;
  top: 227px;
  left: 0;
}

.web .frame {
  flex-direction: column;
  align-items: center;
  gap: 140px;
  height: 4243px;
  display: inline-flex;
  position: relative;
  top: -4px;
  left: 11px;
}

.web .frame-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 180px;
  display: inline-flex;
  position: relative;
}

.web .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 156px;
  display: inline-flex;
  position: relative;
}

.web .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 128px;
  display: inline-flex;
  position: relative;
}

.web .div-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 64px;
  display: inline-flex;
  position: relative;
}

.web .overlap-group-wrapper {
  width: 1427.75px;
  height: 432px;
  position: relative;
}

.web .overlap-group {
  width: 1428px;
  height: 432px;
  position: relative;
}

.web .content-2 {
  width: 666px;
  height: 341px;
  position: absolute;
  top: 91px;
  left: 0;
}

.web .button {
  all: unset;
  background-color: var(--primary);
  box-sizing: border-box;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 138px;
  padding: 16px 32px;
  display: flex;
  position: absolute;
  top: 293px;
  left: 0;
}

.web .text-wrapper {
  color: var(--surface-main);
  font-family: var(--button-default-font-family);
  font-size: var(--button-default-font-size);
  font-style: var(--button-default-font-style);
  font-weight: var(--button-default-font-weight);
  letter-spacing: var(--button-default-letter-spacing);
  line-height: var(--button-default-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-left: -11.55px;
  margin-right: -11.55px;
  position: relative;
}

.web .typography {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 665px;
  height: 290px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.web .text-wrapper-2 {
  color: var(--surface-main);
  font-family: var(--headline-1-font-family);
  font-size: var(--headline-1-font-size);
  font-style: var(--headline-1-font-style);
  font-weight: var(--headline-1-font-weight);
  letter-spacing: var(--headline-1-letter-spacing);
  line-height: var(--headline-1-line-height);
  width: 720px;
  margin-top: -1px;
  margin-right: -55px;
  position: relative;
}

.web .fourleaf-offers {
  color: var(--secondary-2);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 665px;
  position: relative;
}

.web .chart-wrapper {
  width: 854px;
  height: 432px;
  position: absolute;
  top: 0;
  left: 574px;
}

.web .chart {
  background-image: url("table.a83b55b0.png");
  background-size: 100% 100%;
  height: 432px;
}

.web .overlap-2 {
  width: 676px;
  height: 399px;
  position: relative;
  top: 29px;
  left: 148px;
}

.web .chart-2 {
  width: 676px;
  height: 124px;
  position: absolute;
  top: 275px;
  left: 0;
}

.web .list-column-wrapper {
  width: 658px;
  height: 279px;
  position: absolute;
  top: 0;
  left: 11px;
}

.web .list-column {
  height: 279px;
  position: relative;
}

.web .column {
  width: 9px;
  height: 10px;
  position: absolute;
  top: 261px;
  left: 52px;
  transform: rotate(180deg);
}

.web .overlap-group-2 {
  height: 10px;
  position: relative;
}

.web .stick {
  width: 1px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle {
  background-color: var(--success);
  width: 9px;
  height: 4px;
  position: absolute;
  top: 2px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-2 {
  width: 9px;
  height: 39px;
  position: absolute;
  top: 226px;
  left: 0;
  transform: rotate(180deg);
}

.web .overlap-3 {
  height: 39px;
  position: relative;
}

.web .stick-2 {
  width: 1px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-2 {
  background-color: var(--success);
  width: 9px;
  height: 10px;
  position: absolute;
  top: 15px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-3 {
  width: 9px;
  height: 39px;
  position: absolute;
  top: 219px;
  left: 13px;
  transform: rotate(180deg);
}

.web .candle-3 {
  background-color: var(--critical);
  width: 9px;
  height: 19px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-4 {
  width: 9px;
  height: 39px;
  position: absolute;
  top: 152px;
  left: 91px;
  transform: rotate(180deg);
}

.web .candle-4 {
  background-color: var(--critical);
  width: 9px;
  height: 19px;
  position: absolute;
  top: 14px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-5 {
  width: 9px;
  height: 21px;
  position: absolute;
  top: 158px;
  left: 103px;
  transform: rotate(180deg);
}

.web .overlap-4 {
  height: 21px;
  position: relative;
}

.web .stick-3 {
  width: 1px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-5 {
  background-color: var(--success);
  width: 9px;
  height: 19px;
  position: absolute;
  top: 2px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-6 {
  width: 9px;
  height: 50px;
  position: absolute;
  top: 158px;
  left: 116px;
  transform: rotate(180deg);
}

.web .overlap-5 {
  height: 50px;
  position: relative;
}

.web .stick-4 {
  width: 1px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-6 {
  background-color: var(--critical);
  width: 9px;
  height: 34px;
  position: absolute;
  top: 17px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-7 {
  width: 9px;
  height: 20px;
  position: absolute;
  top: 181px;
  left: 129px;
  transform: rotate(180deg);
}

.web .overlap-6 {
  height: 20px;
  position: relative;
}

.web .stick-5 {
  width: 1px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-7 {
  background-color: var(--critical);
  width: 9px;
  height: 6px;
  position: absolute;
  top: 4px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-8 {
  width: 9px;
  height: 20px;
  position: absolute;
  top: 209px;
  left: 181px;
  transform: rotate(180deg);
}

.web .candle-8 {
  background-color: var(--critical);
  width: 9px;
  height: 4px;
  position: absolute;
  top: 10px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-9 {
  width: 9px;
  height: 15px;
  position: absolute;
  top: 185px;
  left: 142px;
  transform: rotate(180deg);
}

.web .overlap-7 {
  height: 15px;
  position: relative;
}

.web .stick-6 {
  width: 1px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-9 {
  background-color: var(--success);
  width: 9px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-10 {
  width: 9px;
  height: 22px;
  position: absolute;
  top: 203px;
  left: 246px;
  transform: rotate(180deg);
}

.web .overlap-8 {
  height: 22px;
  position: relative;
}

.web .stick-7 {
  width: 1px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-10 {
  background-color: var(--success);
  width: 9px;
  height: 4px;
  position: absolute;
  top: 4px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-11 {
  width: 9px;
  height: 19px;
  position: absolute;
  top: 200px;
  left: 285px;
  transform: rotate(180deg);
}

.web .overlap-9 {
  height: 19px;
  position: relative;
}

.web .stick-8 {
  width: 1px;
  height: 19px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-11 {
  background-color: var(--critical);
  width: 9px;
  height: 7px;
  position: absolute;
  top: 7px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-12 {
  width: 9px;
  height: 25px;
  position: absolute;
  top: 166px;
  left: 403px;
  transform: rotate(180deg);
}

.web .overlap-10 {
  height: 25px;
  position: relative;
}

.web .stick-9 {
  width: 1px;
  height: 19px;
  position: absolute;
  top: 6px;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-12 {
  background-color: var(--critical);
  width: 9px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-13 {
  width: 9px;
  height: 29px;
  position: absolute;
  top: 167px;
  left: 416px;
  transform: rotate(180deg);
}

.web .overlap-11 {
  height: 29px;
  position: relative;
}

.web .stick-10 {
  width: 1px;
  height: 29px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-13 {
  background-color: var(--success);
  width: 9px;
  height: 21px;
  position: absolute;
  top: 6px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-14 {
  width: 9px;
  height: 29px;
  position: absolute;
  top: 73px;
  left: 442px;
  transform: rotate(180deg);
}

.web .candle-14 {
  background-color: var(--success);
  width: 9px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-15 {
  width: 9px;
  height: 29px;
  position: absolute;
  top: 65px;
  left: 455px;
  transform: rotate(180deg);
}

.web .candle-15 {
  background-color: var(--success);
  width: 9px;
  height: 13px;
  position: absolute;
  top: 15px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-16 {
  width: 9px;
  height: 31px;
  position: absolute;
  top: 65px;
  left: 468px;
  transform: rotate(180deg);
}

.web .overlap-12 {
  height: 31px;
  position: relative;
}

.web .stick-11 {
  width: 1px;
  height: 31px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-16 {
  background-color: var(--critical);
  width: 9px;
  height: 13px;
  position: absolute;
  top: 18px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-17 {
  width: 9px;
  height: 57px;
  position: absolute;
  top: 45px;
  left: 481px;
  transform: rotate(180deg);
}

.web .overlap-13 {
  height: 57px;
  position: relative;
}

.web .stick-12 {
  width: 1px;
  height: 57px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-17 {
  background-color: var(--success);
  width: 9px;
  height: 14px;
  position: absolute;
  top: 24px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-18 {
  width: 9px;
  height: 44px;
  position: absolute;
  top: 28px;
  left: 494px;
  transform: rotate(180deg);
}

.web .overlap-14 {
  height: 44px;
  position: relative;
}

.web .stick-13 {
  width: 1px;
  height: 44px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-18 {
  background-color: var(--success);
  width: 9px;
  height: 30px;
  position: absolute;
  top: 8px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-19 {
  width: 9px;
  height: 44px;
  position: absolute;
  top: 28px;
  left: 507px;
  transform: rotate(180deg);
}

.web .candle-19 {
  background-color: var(--critical);
  width: 9px;
  height: 30px;
  position: absolute;
  top: 8px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-20 {
  width: 9px;
  height: 44px;
  position: absolute;
  top: 58px;
  left: 520px;
  transform: rotate(180deg);
}

.web .column-21 {
  width: 9px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 584px;
  transform: rotate(180deg);
}

.web .overlap-15 {
  height: 60px;
  position: relative;
}

.web .stick-14 {
  width: 1px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-20 {
  background-color: var(--critical);
  width: 9px;
  height: 30px;
  position: absolute;
  top: 29px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-22 {
  width: 9px;
  height: 48px;
  position: absolute;
  top: 29px;
  left: 610px;
  transform: rotate(180deg);
}

.web .overlap-16 {
  height: 48px;
  position: relative;
}

.web .stick-15 {
  width: 1px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-21 {
  background-color: var(--critical);
  width: 9px;
  height: 24px;
  position: absolute;
  top: 13px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-23 {
  width: 9px;
  height: 27px;
  position: absolute;
  top: 57px;
  left: 623px;
  transform: rotate(180deg);
}

.web .overlap-17 {
  height: 27px;
  position: relative;
}

.web .stick-16 {
  width: 1px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-22 {
  background-color: var(--critical);
  width: 9px;
  height: 4px;
  position: absolute;
  top: 17px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-24 {
  width: 9px;
  height: 67px;
  position: absolute;
  top: 61px;
  left: 636px;
  transform: rotate(180deg);
}

.web .overlap-18 {
  height: 67px;
  position: relative;
}

.web .stick-17 {
  width: 1px;
  height: 67px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-23 {
  background-color: var(--critical);
  width: 9px;
  height: 47px;
  position: absolute;
  top: 14px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-25 {
  width: 9px;
  height: 59px;
  position: absolute;
  top: 111px;
  left: 649px;
  transform: rotate(180deg);
}

.web .overlap-19 {
  height: 59px;
  position: relative;
}

.web .stick-18 {
  width: 1px;
  height: 59px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-24 {
  background-color: var(--critical);
  width: 9px;
  height: 36px;
  position: absolute;
  top: 21px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-26 {
  width: 9px;
  height: 29px;
  position: absolute;
  top: 72px;
  left: 533px;
  transform: rotate(180deg);
}

.web .candle-25 {
  background-color: var(--success);
  width: 9px;
  height: 12px;
  position: absolute;
  top: 8px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-27 {
  width: 9px;
  height: 30px;
  position: absolute;
  top: 59px;
  left: 545px;
  transform: rotate(180deg);
}

.web .overlap-20 {
  height: 30px;
  position: relative;
}

.web .candle-26 {
  background-color: var(--success);
  width: 9px;
  height: 23px;
  position: absolute;
  top: 8px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-28 {
  width: 9px;
  height: 46px;
  position: absolute;
  top: 31px;
  left: 597px;
  transform: rotate(180deg);
}

.web .overlap-21 {
  height: 46px;
  position: relative;
}

.web .stick-19 {
  width: 1px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .column-29 {
  width: 9px;
  height: 29px;
  position: absolute;
  top: 45px;
  left: 558px;
  transform: rotate(180deg);
}

.web .candle-27 {
  background-color: var(--success);
  width: 9px;
  height: 4px;
  position: absolute;
  top: 15px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-30 {
  width: 9px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 571px;
  transform: rotate(180deg);
}

.web .candle-28 {
  background-color: var(--success);
  width: 9px;
  height: 32px;
  position: absolute;
  top: 38px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-31 {
  width: 9px;
  height: 12px;
  position: absolute;
  top: 197px;
  left: 298px;
  transform: rotate(180deg);
}

.web .overlap-22 {
  height: 12px;
  position: relative;
}

.web .stick-20 {
  width: 1px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-29 {
  background-color: var(--critical);
  width: 9px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-32 {
  width: 9px;
  height: 20px;
  position: absolute;
  top: 202px;
  left: 272px;
  transform: rotate(180deg);
}

.web .candle-30 {
  background-color: var(--success);
  width: 9px;
  height: 3px;
  position: absolute;
  top: 13px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-33 {
  width: 9px;
  height: 46px;
  position: absolute;
  top: 146px;
  left: 390px;
  transform: rotate(180deg);
}

.web .candle-31 {
  background-color: var(--success);
  width: 9px;
  height: 2px;
  position: absolute;
  top: 22px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-34 {
  width: 9px;
  height: 20px;
  position: absolute;
  top: 196px;
  left: 310px;
  transform: rotate(180deg);
}

.web .candle-32 {
  background-color: var(--success);
  width: 9px;
  height: 1px;
  position: absolute;
  top: 7px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-35 {
  width: 9px;
  height: 37px;
  position: absolute;
  top: 178px;
  left: 323px;
  transform: rotate(180deg);
}

.web .overlap-23 {
  height: 37px;
  position: relative;
}

.web .candle-33 {
  background-color: var(--success);
  width: 9px;
  height: 5px;
  position: absolute;
  top: 6px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-36 {
  width: 9px;
  height: 52px;
  position: absolute;
  top: 162px;
  left: 336px;
  transform: rotate(180deg);
}

.web .overlap-24 {
  height: 52px;
  position: relative;
}

.web .stick-21 {
  width: 1px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-34 {
  background-color: var(--success);
  width: 9px;
  height: 28px;
  position: absolute;
  top: 10px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-37 {
  width: 9px;
  height: 69px;
  position: absolute;
  top: 129px;
  left: 349px;
  transform: rotate(180deg);
}

.web .overlap-25 {
  height: 69px;
  position: relative;
}

.web .stick-22 {
  width: 1px;
  height: 69px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-35 {
  background-color: var(--success);
  width: 9px;
  height: 8px;
  position: absolute;
  top: 22px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-38 {
  width: 9px;
  height: 29px;
  position: absolute;
  top: 192px;
  left: 259px;
  transform: rotate(180deg);
}

.web .candle-36 {
  background-color: var(--success);
  width: 9px;
  height: 9px;
  position: absolute;
  top: 4px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-39 {
  width: 9px;
  height: 23px;
  position: absolute;
  top: 186px;
  left: 155px;
  transform: rotate(180deg);
}

.web .overlap-26 {
  height: 23px;
  position: relative;
}

.web .stick-23 {
  width: 1px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-37 {
  background-color: var(--critical);
  width: 9px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-40 {
  width: 9px;
  height: 23px;
  position: absolute;
  top: 200px;
  left: 194px;
  transform: rotate(180deg);
}

.web .candle-38 {
  background-color: var(--success);
  width: 9px;
  height: 16px;
  position: absolute;
  top: 4px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-41 {
  width: 9px;
  height: 63px;
  position: absolute;
  top: 185px;
  left: 220px;
  transform: rotate(180deg);
}

.web .overlap-27 {
  height: 63px;
  position: relative;
}

.web .stick-24 {
  width: 1px;
  height: 63px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-39 {
  background-color: var(--success);
  width: 9px;
  height: 36px;
  position: absolute;
  top: 25px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-42 {
  width: 9px;
  height: 94px;
  position: absolute;
  top: 163px;
  left: 377px;
}

.web .overlap-28 {
  height: 94px;
  position: relative;
}

.web .stick-25 {
  width: 1px;
  height: 94px;
  position: absolute;
  top: 0;
  left: 4px;
}

.web .candle-40 {
  background-color: var(--success);
  width: 9px;
  height: 73px;
  position: absolute;
  top: 7px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-43 {
  width: 9px;
  height: 94px;
  position: absolute;
  top: 89px;
  left: 429px;
}

.web .column-44 {
  width: 9px;
  height: 32px;
  position: absolute;
  top: 203px;
  left: 207px;
  transform: rotate(180deg);
}

.web .overlap-group-3 {
  height: 32px;
  position: relative;
}

.web .stick-26 {
  width: 1px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 4px;
  transform: rotate(-180deg);
}

.web .candle-41 {
  background-color: var(--critical);
  width: 9px;
  height: 20px;
  position: absolute;
  top: 12px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-45 {
  width: 9px;
  height: 50px;
  position: absolute;
  top: 187px;
  left: 233px;
  transform: rotate(180deg);
}

.web .candle-42 {
  background-color: var(--critical);
  width: 9px;
  height: 34px;
  position: absolute;
  top: 16px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-46 {
  width: 9px;
  height: 109px;
  position: absolute;
  top: 166px;
  left: 364px;
}

.web .overlap-29 {
  height: 109px;
  position: relative;
}

.web .stick-27 {
  width: 1px;
  height: 109px;
  position: absolute;
  top: 0;
  left: 4px;
}

.web .candle-43 {
  background-color: var(--critical);
  width: 9px;
  height: 73px;
  position: absolute;
  top: 4px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-47 {
  width: 9px;
  height: 29px;
  position: absolute;
  top: 198px;
  left: 168px;
  transform: rotate(180deg);
}

.web .candle-44 {
  background-color: var(--critical);
  width: 9px;
  height: 15px;
  position: absolute;
  top: 11px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-48 {
  width: 9px;
  height: 21px;
  position: absolute;
  top: 252px;
  left: 26px;
}

.web .stick-28 {
  width: 1px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 4px;
}

.web .candle-45 {
  background-color: var(--critical);
  width: 9px;
  height: 6px;
  position: absolute;
  top: 6px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-49 {
  width: 9px;
  height: 21px;
  position: absolute;
  top: 258px;
  left: 39px;
}

.web .column-50 {
  width: 9px;
  height: 130px;
  position: absolute;
  top: 142px;
  left: 78px;
}

.web .overlap-30 {
  height: 130px;
  position: relative;
}

.web .stick-29 {
  width: 1px;
  height: 130px;
  position: absolute;
  top: 0;
  left: 4px;
}

.web .candle-46 {
  background-color: var(--success);
  width: 9px;
  height: 111px;
  position: absolute;
  top: 16px;
  left: 0;
  transform: rotate(180deg);
}

.web .LIST-CRYPTO {
  box-shadow: var(--drop-dark);
  background-image: url("background-2.67d5ec6a.svg");
  background-size: 100% 100%;
  width: 1427px;
  height: 236px;
  position: relative;
}

.web .card-assets {
  -webkit-backdrop-filter: blur(4px) brightness();
  backdrop-filter: blur(4px) brightness();
  box-shadow: var(--background-blur);
  border-radius: 8px;
  width: 320px;
  height: 180px;
  position: absolute;
  top: 28px;
  left: 29px;
}

.web .text-wrapper-3 {
  color: var(--secondary);
  font-family: var(--body-02-bold-font-family);
  font-size: var(--body-02-bold-font-size);
  font-style: var(--body-02-bold-font-style);
  font-weight: var(--body-02-bold-font-weight);
  letter-spacing: var(--body-02-bold-letter-spacing);
  line-height: var(--body-02-bold-line-height);
  text-align: right;
  white-space: nowrap;
  position: absolute;
  top: 127px;
  left: 257px;
}

.web .text-wrapper-4 {
  color: var(--surface-main);
  letter-spacing: -.24px;
  white-space: nowrap;
  font-family: DM Sans, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  position: absolute;
  top: 123px;
  left: 28px;
}

.web .text-wrapper-5 {
  color: var(--surface-main);
  font-family: var(--caption-bold-font-family);
  font-size: var(--caption-bold-font-size);
  font-style: var(--caption-bold-font-style);
  font-weight: var(--caption-bold-font-weight);
  letter-spacing: var(--caption-bold-letter-spacing);
  line-height: var(--caption-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: 91px;
  left: 28px;
}

.web .chart-3 {
  width: 120px;
  height: 38px;
  position: absolute;
  top: 28px;
  left: 173px;
}

.web .frame-5 {
  background-color: var(--success);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  display: inline-flex;
  position: absolute;
  top: 70px;
  left: 225px;
}

.web .bold-arrow-up {
  width: 16px;
  height: 16px;
  position: relative;
}

.web .text-wrapper-6 {
  color: var(--surface-main);
  font-family: var(--body-03-bold-font-family);
  font-size: var(--body-03-bold-font-size);
  font-style: var(--body-03-bold-font-style);
  font-weight: var(--body-03-bold-font-weight);
  letter-spacing: var(--body-03-bold-letter-spacing);
  line-height: var(--body-03-bold-line-height);
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.web .img-2 {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 28px;
  left: 28px;
}

.web .vector-wrapper {
  background-color: #f7931a;
  border-radius: 100px;
  height: 44px;
  position: relative;
}

.web .vector {
  width: 21px;
  height: 27px;
  position: absolute;
  top: 8px;
  left: 11px;
}

.web .card-assets-2 {
  -webkit-backdrop-filter: blur(4px) brightness();
  backdrop-filter: blur(4px) brightness();
  background-color: #1b462acc;
  border-radius: 8px;
  width: 320px;
  height: 180px;
  position: absolute;
  top: 28px;
  left: 373px;
}

.web .text-wrapper-7 {
  color: var(--secondary);
  font-family: var(--body-02-bold-font-family);
  font-size: var(--body-02-bold-font-size);
  font-style: var(--body-02-bold-font-style);
  font-weight: var(--body-02-bold-font-weight);
  letter-spacing: var(--body-02-bold-letter-spacing);
  line-height: var(--body-02-bold-line-height);
  text-align: right;
  white-space: nowrap;
  position: absolute;
  top: 127px;
  left: 258px;
}

.web .chart-4 {
  width: 136px;
  height: 31px;
  position: absolute;
  top: 27px;
  left: 157px;
}

.web .frame-6 {
  background-color: var(--critical);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  display: inline-flex;
  position: absolute;
  top: 70px;
  left: 226px;
}

.web .card-assets-3 {
  -webkit-backdrop-filter: blur(4px) brightness();
  backdrop-filter: blur(4px) brightness();
  border-radius: 8px;
  width: 320px;
  height: 180px;
  position: absolute;
  top: 28px;
  left: 717px;
}

.web .text-wrapper-8 {
  color: var(--secondary);
  font-family: var(--body-02-bold-font-family);
  font-size: var(--body-02-bold-font-size);
  font-style: var(--body-02-bold-font-style);
  font-weight: var(--body-02-bold-font-weight);
  letter-spacing: var(--body-02-bold-letter-spacing);
  line-height: var(--body-02-bold-line-height);
  text-align: right;
  white-space: nowrap;
  position: absolute;
  top: 127px;
  left: 246px;
}

.web .text-wrapper-9 {
  color: var(--surface-main);
  letter-spacing: -.24px;
  width: 175px;
  font-family: DM Sans, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  position: absolute;
  top: 123px;
  left: 28px;
}

.web .frame-7 {
  background-color: var(--success);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 80px;
  padding: 2px 8px;
  display: flex;
  position: absolute;
  top: 70px;
  left: 212px;
}

.web .card-assets-4 {
  -webkit-backdrop-filter: blur(4px) brightness();
  backdrop-filter: blur(4px) brightness();
  border-radius: 8px;
  width: 320px;
  height: 180px;
  position: absolute;
  top: 28px;
  left: 1061px;
}

.web .frame-8 {
  background-color: var(--success);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 79px;
  padding: 2px 8px;
  display: flex;
  position: absolute;
  top: 70px;
  left: 213px;
}

.web .text-wrapper-10 {
  color: var(--secondary);
  font-family: var(--body-02-bold-font-family);
  font-size: var(--body-02-bold-font-size);
  font-style: var(--body-02-bold-font-style);
  font-weight: var(--body-02-bold-font-weight);
  letter-spacing: var(--body-02-bold-letter-spacing);
  line-height: var(--body-02-bold-line-height);
  text-align: right;
  white-space: nowrap;
  position: absolute;
  top: 127px;
  left: 256px;
}

.web .content-3 {
  width: 1427px;
  height: 406px;
  position: relative;
}

.web .text {
  width: 533px;
  height: 302px;
  position: absolute;
  top: 75px;
  left: 898px;
}

.web .button-2 {
  all: unset;
  background-color: var(--primary);
  box-sizing: border-box;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 138px;
  padding: 16px 32px;
  display: flex;
  position: absolute;
  top: 254px;
  left: 0;
}

.web .overlap-group-4 {
  width: 529px;
  height: 182px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .subtitle {
  color: #b1b5c3;
  font-family: var(--body-02-font-family);
  font-size: var(--body-02-font-size);
  font-style: var(--body-02-font-style);
  font-weight: var(--body-02-font-weight);
  letter-spacing: var(--body-02-letter-spacing);
  line-height: var(--body-02-line-height);
  width: 529px;
  position: absolute;
  top: 126px;
  left: 0;
}

.web .title {
  color: var(--surface-main);
  font-family: var(--headline-2-font-family);
  font-size: var(--headline-2-font-size);
  font-style: var(--headline-2-font-style);
  font-weight: var(--headline-2-font-weight);
  letter-spacing: var(--headline-2-letter-spacing);
  line-height: var(--headline-2-line-height);
  width: 528px;
  position: absolute;
  top: 0;
  left: 1px;
}

.web .features {
  width: 701px;
  height: 406px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .feature {
  width: 334px;
  height: 288px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .group-wrapper {
  height: 288px;
}

.web .group-2 {
  -webkit-backdrop-filter: blur(40px) brightness();
  backdrop-filter: blur(40px) brightness();
  background-color: #242f2266;
  border-radius: 12px;
  width: 334px;
  height: 288px;
}

.web .frame-9 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  height: 274px;
  display: inline-flex;
  position: relative;
  top: 6px;
  left: 12px;
}

.web .group-3 {
  width: 105.17px;
  height: 105.17px;
  position: relative;
}

.web .title-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.web .p {
  color: var(--surface-main);
  font-family: var(--headline-5-font-family);
  font-size: var(--headline-5-font-size);
  font-style: var(--headline-5-font-style);
  font-weight: var(--headline-5-font-weight);
  letter-spacing: var(--headline-5-letter-spacing);
  line-height: var(--headline-5-line-height);
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.web .group-4 {
  width: 334px;
  height: 262px;
  position: absolute;
  top: 144px;
  left: 367px;
}

.web .group-5 {
  height: 262px;
}

.web .feature-wrapper {
  -webkit-backdrop-filter: blur(4px) brightness();
  backdrop-filter: blur(4px) brightness();
  background-color: #242f2266;
  border-radius: 12px;
  width: 334px;
  height: 262px;
}

.web .feature-2 {
  width: 287px;
  height: 197px;
  position: relative;
  top: 34px;
  left: 23px;
}

.web .frame-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  display: inline-flex;
  position: relative;
}

.web .group-6 {
  width: 105px;
  height: 105px;
  position: relative;
}

.web .group-7 {
  width: 1728px;
  height: 199px;
  position: relative;
}

.web .frame-11 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 1728px;
  display: flex;
  position: relative;
}

.web .background-2 {
  object-fit: cover;
  width: 1717px;
  height: 199px;
  position: relative;
}

.web .CALL-TO-ACTION {
  width: 1728px;
  height: 140px;
  position: absolute;
  top: 30px;
  left: 0;
}

.web .call-to-action {
  align-items: center;
  gap: 644px;
  width: 1427px;
  display: flex;
  position: relative;
  top: 36px;
  left: 150px;
}

.web .text-2 {
  width: 440px;
  height: 72px;
  position: relative;
}

.web .text-wrapper-11 {
  color: var(--surface-main);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: DM Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 48px;
  left: 0;
}

.web .text-wrapper-12 {
  color: var(--surface-main);
  font-family: var(--headline-4-font-family);
  font-size: var(--headline-4-font-size);
  font-style: var(--headline-4-font-style);
  font-weight: var(--headline-4-font-weight);
  letter-spacing: var(--headline-4-letter-spacing);
  line-height: var(--headline-4-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.web .label-wrapper {
  all: unset;
  background-color: var(--surface-main);
  box-sizing: border-box;
  border-radius: 90px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 187px;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.web .label {
  color: var(--on-surface);
  font-family: var(--button-default-font-family);
  font-size: var(--button-default-font-size);
  font-style: var(--button-default-font-style);
  font-weight: var(--button-default-font-weight);
  letter-spacing: var(--button-default-letter-spacing);
  line-height: var(--button-default-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.web .group-8 {
  width: 1430px;
  height: 727px;
  position: relative;
}

.web .group-9 {
  height: 727px;
  position: relative;
}

.web .content-4 {
  width: 606px;
  height: 492px;
  position: absolute;
  top: 126px;
  left: 824px;
}

.web .frame-12 {
  align-items: center;
  gap: 142px;
  width: 606px;
  display: flex;
  position: relative;
}

.web .text-3 {
  width: 636px;
  height: 492px;
  margin-right: -30px;
  position: relative;
}

.web .feature-3 {
  width: 377px;
  height: 88px;
  position: absolute;
  top: 174px;
  left: 0;
}

.web .overlap-group-5 {
  border-radius: 12px;
  width: 88px;
  height: 88px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .icon {
  background-color: var(--darkhover);
  opacity: .88;
  border-radius: 12px;
  width: 88px;
  height: 88px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .group-10 {
  width: 65px;
  height: 65px;
  position: absolute;
  top: 11px;
  left: 12px;
}

.web .frame-13 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 443px;
  display: flex;
  position: absolute;
  top: 27px;
  left: 120px;
}

.web .title-2 {
  color: var(--surface-main);
  font-family: var(--headline-5-font-family);
  font-size: var(--headline-5-font-size);
  font-style: var(--headline-5-font-style);
  font-weight: var(--headline-5-font-weight);
  letter-spacing: var(--headline-5-letter-spacing);
  line-height: var(--headline-5-line-height);
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -124px;
  position: relative;
}

.web .feature-4 {
  width: 547px;
  height: 88px;
  position: absolute;
  top: 404px;
  left: 0;
}

.web .img-wrapper {
  background-color: var(--darkhover);
  border-radius: 12px;
  width: 88px;
  height: 88px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .group-11 {
  width: 65px;
  height: 63px;
  position: absolute;
  top: 13px;
  left: 11px;
}

.web .frame-14 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 12px;
  left: 120px;
}

.web .title-3 {
  color: var(--surface-main);
  font-family: var(--headline-5-font-family);
  font-size: var(--headline-5-font-size);
  font-style: var(--headline-5-font-style);
  font-weight: var(--headline-5-font-weight);
  letter-spacing: var(--headline-5-letter-spacing);
  line-height: var(--headline-5-line-height);
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.web .feature-5 {
  width: 572px;
  height: 88px;
  position: absolute;
  top: 288px;
  left: 0;
}

.web .group-12 {
  width: 64px;
  height: 59px;
  position: absolute;
  top: 14px;
  left: 12px;
}

.web .frame-15 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.web .title-4 {
  color: var(--surface-main);
  font-family: var(--headline-2-font-family);
  font-size: var(--headline-2-font-size);
  font-style: var(--headline-2-font-style);
  font-weight: var(--headline-2-font-weight);
  letter-spacing: var(--headline-2-letter-spacing);
  line-height: var(--headline-2-line-height);
  width: 636px;
  margin-top: -1px;
  position: relative;
}

.web .subtitle-2 {
  color: var(--secondary-2);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 573px;
  position: relative;
}

.web .bittrex-inc-logo {
  width: 194px;
  height: 194px;
  position: absolute;
  top: 286px;
  left: 431px;
}

.web .rectangle {
  border-radius: 26px;
  width: 353px;
  height: 130px;
  position: absolute;
  top: 416px;
  left: 0;
  box-shadow: 0 10px 10px #53535340;
}

.web .div-2 {
  width: 719px;
  height: 727px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .group-13 {
  height: 727px;
}

.web .overlap-31 {
  width: 719px;
  height: 727px;
  position: relative;
}

.web .overlap-group-6 {
  width: 715px;
  height: 727px;
  position: relative;
}

.web .ellipse {
  border: 2px dashed #e7e7e733;
  border-radius: 357.5px / 327.5px;
  width: 715px;
  height: 655px;
  position: absolute;
  top: 72px;
  left: 0;
}

.web .ellipse-2 {
  border: 1.66px dashed #e7e7e733;
  border-radius: 296.47px;
  width: 593px;
  height: 593px;
  position: absolute;
  top: 0;
  left: 111px;
}

.web .group-14 {
  width: 713px;
  height: 468px;
  position: absolute;
  top: 126px;
  left: 0;
}

.web .overlap-32 {
  background-image: url("chart.496bb2eb.png");
  background-size: 100% 100%;
  width: 745px;
  height: 548px;
  position: relative;
  left: -16px;
}

.web .chart-5 {
  width: 538px;
  height: 196px;
  position: relative;
  top: 136px;
  left: 140px;
}

.web .overlap-33 {
  height: 196px;
  position: relative;
}

.web .vector-2 {
  width: 525px;
  height: 167px;
  position: absolute;
  top: 19px;
  left: 6px;
}

.web .list-column-2 {
  width: 538px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .column-51 {
  width: 13px;
  height: 32px;
  position: absolute;
  top: 116px;
  left: 0;
}

.web .stick-30 {
  width: 1px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 6px;
}

.web .candle-47 {
  background-color: var(--success);
  width: 13px;
  height: 22px;
  position: absolute;
  top: 2px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-52 {
  width: 13px;
  height: 46px;
  position: absolute;
  top: 100px;
  left: 20px;
}

.web .stick-31 {
  width: 1px;
  height: 41px;
  position: absolute;
  top: 0;
  left: 6px;
}

.web .candle-48 {
  background-color: var(--critical);
  width: 13px;
  height: 28px;
  position: absolute;
  top: 18px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-53 {
  width: 13px;
  height: 37px;
  position: absolute;
  top: 118px;
  left: 40px;
}

.web .candle-49 {
  background-color: var(--success);
  width: 13px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.web .stick-32 {
  width: 1px;
  height: 25px;
  position: absolute;
  top: 12px;
  left: 6px;
}

.web .column-54 {
  width: 13px;
  height: 36px;
  position: absolute;
  top: 160px;
  left: 101px;
}

.web .overlap-34 {
  height: 36px;
  position: relative;
}

.web .stick-33 {
  width: 1px;
  height: 36px;
  position: absolute;
  top: 0;
  left: 6px;
}

.web .candle-50 {
  background-color: var(--success);
  width: 13px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-55 {
  width: 13px;
  height: 36px;
  position: absolute;
  top: 151px;
  left: 161px;
}

.web .candle-51 {
  background-color: var(--success);
  width: 13px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.web .column-56 {
  width: 13px;
  height: 20px;
  position: absolute;
  top: 131px;
  left: 182px;
}

.web .stick-34 {
  width: 1px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 6px;
}

.web .candle-52 {
  background-color: var(--success);
  width: 13px;
  height: 12px;
  position: absolute;
  top: 8px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-57 {
  width: 13px;
  height: 15px;
  position: absolute;
  top: 116px;
  left: 262px;
  transform: rotate(180deg);
}

.web .stick-35 {
  width: 1px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 6px;
  transform: rotate(-180deg);
}

.web .candle-53 {
  background-color: var(--success);
  width: 13px;
  height: 9px;
  position: absolute;
  top: 2px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-58 {
  width: 13px;
  height: 12px;
  position: absolute;
  top: 132px;
  left: 363px;
  transform: rotate(180deg);
}

.web .stick-36 {
  width: 1px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 6px;
  transform: rotate(-180deg);
}

.web .candle-54 {
  background-color: var(--success);
  width: 13px;
  height: 4px;
  position: absolute;
  top: 2px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-59 {
  width: 13px;
  height: 43px;
  position: absolute;
  top: 93px;
  left: 282px;
  transform: rotate(180deg);
}

.web .overlap-35 {
  height: 43px;
  position: relative;
}

.web .stick-37 {
  width: 1px;
  height: 43px;
  position: absolute;
  top: 0;
  left: 6px;
  transform: rotate(-180deg);
}

.web .candle-55 {
  background-color: var(--success);
  width: 13px;
  height: 12px;
  position: absolute;
  top: 17px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-60 {
  width: 13px;
  height: 36px;
  position: absolute;
  top: 115px;
  left: 222px;
}

.web .candle-56 {
  background-color: var(--success);
  width: 13px;
  height: 34px;
  position: absolute;
  top: 2px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-61 {
  width: 13px;
  height: 20px;
  position: absolute;
  top: 131px;
  left: 202px;
}

.web .candle-57 {
  background-color: var(--critical);
  width: 13px;
  height: 12px;
  position: absolute;
  top: 8px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-62 {
  width: 13px;
  height: 20px;
  position: absolute;
  top: 114px;
  left: 242px;
  transform: rotate(180deg);
}

.web .stick-38 {
  width: 1px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 6px;
  transform: rotate(-180deg);
}

.web .candle-58 {
  background-color: var(--critical);
  width: 13px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-63 {
  width: 13px;
  height: 43px;
  position: absolute;
  top: 85px;
  left: 303px;
  transform: rotate(180deg);
}

.web .candle-59 {
  background-color: var(--critical);
  width: 13px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-64 {
  width: 13px;
  height: 43px;
  position: absolute;
  top: 12px;
  left: 424px;
  transform: rotate(180deg);
}

.web .candle-60 {
  background-color: var(--critical);
  width: 13px;
  height: 21px;
  position: absolute;
  top: 16px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-65 {
  width: 13px;
  height: 23px;
  position: absolute;
  top: 18px;
  left: 444px;
  transform: rotate(180deg);
}

.web .stick-39 {
  width: 1px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 6px;
  transform: rotate(-180deg);
}

.web .candle-61 {
  background-color: var(--success);
  width: 13px;
  height: 21px;
  position: absolute;
  top: 2px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-66 {
  width: 13px;
  height: 56px;
  position: absolute;
  top: 18px;
  left: 464px;
  transform: rotate(180deg);
}

.web .overlap-36 {
  height: 56px;
  position: relative;
}

.web .stick-40 {
  width: 1px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 6px;
  transform: rotate(-180deg);
}

.web .candle-62 {
  background-color: var(--critical);
  width: 13px;
  height: 37px;
  position: absolute;
  top: 18px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-67 {
  width: 13px;
  height: 22px;
  position: absolute;
  top: 43px;
  left: 484px;
  transform: rotate(180deg);
}

.web .stick-41 {
  width: 1px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 6px;
  transform: rotate(-180deg);
}

.web .candle-63 {
  background-color: var(--critical);
  width: 13px;
  height: 6px;
  position: absolute;
  top: 4px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-68 {
  width: 13px;
  height: 17px;
  position: absolute;
  top: 47px;
  left: 504px;
  transform: rotate(180deg);
}

.web .overlap-37 {
  height: 17px;
  position: relative;
}

.web .stick-42 {
  width: 1px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 6px;
  transform: rotate(-180deg);
}

.web .candle-64 {
  background-color: var(--success);
  width: 13px;
  height: 11px;
  position: absolute;
  top: 3px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-69 {
  width: 13px;
  height: 25px;
  position: absolute;
  top: 48px;
  left: 525px;
  transform: rotate(180deg);
}

.web .stick-43 {
  width: 1px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 6px;
  transform: rotate(-180deg);
}

.web .candle-65 {
  background-color: var(--critical);
  width: 13px;
  height: 14px;
  position: absolute;
  top: 9px;
  left: 0;
  transform: rotate(-180deg);
}

.web .column-70 {
  width: 13px;
  height: 23px;
  position: absolute;
  top: 160px;
  left: 121px;
}

.web .stick-44 {
  width: 1px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 6px;
}

.web .candle-66 {
  background-color: var(--critical);
  width: 13px;
  height: 6px;
  position: absolute;
  top: 6px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-71 {
  width: 13px;
  height: 23px;
  position: absolute;
  top: 122px;
  left: 323px;
}

.web .column-72 {
  width: 13px;
  height: 23px;
  position: absolute;
  top: 129px;
  left: 343px;
}

.web .column-73 {
  width: 13px;
  height: 23px;
  position: absolute;
  top: 164px;
  left: 141px;
}

.web .candle-67 {
  background-color: var(--critical);
  width: 13px;
  height: 4px;
  position: absolute;
  top: 8px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-74 {
  width: 13px;
  height: 25px;
  position: absolute;
  top: 111px;
  left: 61px;
}

.web .stick-45 {
  width: 1px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 6px;
}

.web .candle-68 {
  background-color: var(--critical);
  width: 13px;
  height: 8px;
  position: absolute;
  top: 7px;
  left: 0;
  transform: rotate(180deg);
}

.web .column-75 {
  width: 13px;
  height: 76px;
  position: absolute;
  top: 119px;
  left: 81px;
}

.web .overlap-38 {
  height: 76px;
  position: relative;
}

.web .stick-46 {
  width: 1px;
  height: 76px;
  position: absolute;
  top: 0;
  left: 6px;
}

.web .candle-69 {
  background-color: var(--critical);
  width: 13px;
  height: 67px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.web .column-76 {
  width: 13px;
  height: 144px;
  position: absolute;
  top: 0;
  left: 404px;
}

.web .overlap-39 {
  height: 144px;
  position: relative;
}

.web .stick-47 {
  width: 1px;
  height: 144px;
  position: absolute;
  top: 0;
  left: 6px;
}

.web .candle-70 {
  background-color: var(--success);
  width: 13px;
  height: 123px;
  position: absolute;
  top: 18px;
  left: 0;
  transform: rotate(180deg);
}

.web .group-15 {
  width: 1442px;
  height: 647px;
  position: relative;
}

.web .group-16 {
  width: 567px;
  height: 218px;
  position: absolute;
  top: 207px;
  left: 0;
}

.web .data-monitoring-for {
  color: var(--surface-main);
  font-family: var(--headline-2-font-family);
  font-size: var(--headline-2-font-size);
  font-style: var(--headline-2-font-style);
  font-weight: var(--headline-2-font-weight);
  letter-spacing: var(--headline-2-letter-spacing);
  line-height: var(--headline-2-line-height);
  width: 563px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .monitor-live-market {
  color: #b1b5c3;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 563px;
  position: absolute;
  top: 134px;
  left: 0;
}

.web .group-17 {
  width: 822px;
  height: 647px;
  position: absolute;
  top: 0;
  left: 620px;
}

.web .simple-centered-dark {
  background-color: #0a0e17;
  border-color: #ffffff30;
  border-top-style: solid;
  border-top-width: 1px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 42px;
  padding: 57px 598px;
  display: inline-flex;
  position: absolute;
  top: 4692px;
  left: 2px;
}

.web .menu {
  width: 532.8px;
  height: 25px;
  position: relative;
}

.web .group-18 {
  width: 409px;
  height: 24px;
  position: relative;
  top: 1px;
  left: 64px;
}

.web .support {
  color: #fff;
  letter-spacing: .3px;
  text-align: center;
  width: 201px;
  font-family: DM Sans, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .join-us {
  color: #fff;
  letter-spacing: .3px;
  text-align: center;
  width: 147px;
  font-family: DM Sans, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  top: 0;
  left: 258px;
}

.web .v {
  width: 262px;
  height: 47.13px;
  position: relative;
}

.web .copyright {
  color: #92989e;
  letter-spacing: .26px;
  text-align: center;
  width: 422px;
  height: 24px;
  font-family: Jost, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.web .frame-16 {
  align-items: flex-start;
  gap: 138px;
  width: 1442px;
  padding: 63px 0;
  display: flex;
  position: absolute;
  top: 3746px;
  left: 143px;
}

.web .group-19 {
  width: 611.23px;
  height: 742px;
  position: relative;
}

.web .overlap-40 {
  width: 611px;
  height: 742px;
  position: relative;
}

.web .IMG-2 {
  width: 599px;
  height: 742px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .group-20 {
  height: 227px;
}

.web .group-21 {
  width: 599px;
  height: 227px;
}

.web .overlap-41 {
  width: 599px;
  height: 227px;
  position: relative;
}

.web .feature-6 {
  width: 599px;
  height: 227px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .overlap-group-7 {
  height: 227px;
  position: relative;
}

.web .background-3 {
  -webkit-backdrop-filter: blur(40px) brightness();
  backdrop-filter: blur(40px) brightness();
  background-color: #ffffff12;
  border-radius: 12px;
  width: 599px;
  height: 226px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .frame-17 {
  width: 212px;
  height: 215px;
  position: absolute;
  top: 12px;
  left: 88px;
}

.web .group-22 {
  width: 222px;
  height: 128px;
  position: absolute;
  top: 34px;
  left: 49px;
}

.web .title-5 {
  color: var(--surface-main);
  font-family: var(--headline-5-font-family);
  font-size: var(--headline-5-font-size);
  font-style: var(--headline-5-font-style);
  font-weight: var(--headline-5-font-weight);
  letter-spacing: var(--headline-5-letter-spacing);
  line-height: var(--headline-5-line-height);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.web .text-wrapper-13 {
  color: #b1b5c3;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 218px;
  position: absolute;
  top: 44px;
  left: 0;
}

.web .group-23 {
  width: 222px;
  height: 72px;
  position: absolute;
  top: 34px;
  left: 343px;
}

.web .title-6 {
  color: var(--surface-main);
  font-family: var(--headline-5-font-family);
  font-size: var(--headline-5-font-size);
  font-style: var(--headline-5-font-style);
  font-weight: var(--headline-5-font-weight);
  letter-spacing: var(--headline-5-letter-spacing);
  line-height: var(--headline-5-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.web .group-24 {
  width: 222px;
  height: 72px;
  position: absolute;
  top: 120px;
  left: 343px;
}

.web .IMG-3 {
  width: 611px;
  height: 397px;
  position: absolute;
  top: 306px;
  left: 0;
}

.web .overlap-42 {
  height: 397px;
  position: relative;
}

.web .overlap-43 {
  width: 611px;
  height: 397px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .vector-3 {
  width: 354px;
  height: 281px;
  position: absolute;
  top: 65px;
  left: 257px;
}

.web .vector-4 {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 100px;
  left: 416px;
}

.web .vector-5 {
  width: 191px;
  height: 167px;
  position: absolute;
  top: 103px;
  left: 0;
}

.web .vector-6 {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 220px;
  left: 154px;
}

.web .vector-7 {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 267px;
  left: 150px;
}

.web .vector-8 {
  width: 104px;
  height: 154px;
  position: absolute;
  top: 0;
  left: 162px;
}

.web .vector-9 {
  width: 79px;
  height: 135px;
  position: absolute;
  top: 262px;
  left: 148px;
}

.web .vector-10 {
  width: 68px;
  height: 53px;
  position: absolute;
  top: 302px;
  left: 481px;
}

.web .vector-11 {
  width: 61px;
  height: 69px;
  position: absolute;
  top: 7px;
  left: 122px;
}

.web .vector-12 {
  width: 48px;
  height: 55px;
  position: absolute;
  top: 92px;
  left: 133px;
}

.web .vector-13 {
  width: 42px;
  height: 32px;
  position: absolute;
  top: 88px;
  left: 72px;
}

.web .vector-14 {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 119px;
  left: 106px;
}

.web .vector-15 {
  width: 18px;
  height: 29px;
  position: absolute;
  top: 44px;
  left: 305px;
}

.web .vector-16 {
  width: 29px;
  height: 18px;
  position: absolute;
  top: 70px;
  left: 121px;
}

.web .vector-17 {
  width: 17px;
  height: 18px;
  position: absolute;
  top: 272px;
  left: 473px;
}

.web .vector-18 {
  width: 30px;
  height: 14px;
  position: absolute;
  top: 287px;
  left: 513px;
}

.web .vector-19 {
  width: 20px;
  height: 17px;
  position: absolute;
  top: 72px;
  left: 86px;
}

.web .vector-20 {
  width: 11px;
  height: 22px;
  position: absolute;
  top: 305px;
  left: 361px;
}

.web .vector-21 {
  width: 12px;
  height: 24px;
  position: absolute;
  top: 159px;
  left: 277px;
}

.web .vector-22 {
  width: 15px;
  height: 11px;
  position: absolute;
  top: 41px;
  left: 318px;
}

.web .vector-23 {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 206px;
  left: 508px;
}

.web .vector-24 {
  width: 24px;
  height: 23px;
  position: absolute;
  top: 71px;
  left: 380px;
}

.web .vector-25 {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 293px;
  left: 468px;
}

.web .vector-26 {
  width: 18px;
  height: 19px;
  position: absolute;
  top: 274px;
  left: 449px;
}

.web .vector-27 {
  width: 16px;
  height: 14px;
  position: absolute;
  top: 73px;
  left: 112px;
}

.web .vector-28 {
  width: 11px;
  height: 15px;
  position: absolute;
  top: 91px;
  left: 111px;
}

.web .vector-29 {
  width: 13px;
  height: 10px;
  position: absolute;
  top: 77px;
  left: 521px;
}

.web .vector-30 {
  width: 13px;
  height: 10px;
  position: absolute;
  top: 131px;
  left: 249px;
}

.web .vector-31 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 66px;
  left: 77px;
}

.web .vector-32 {
  width: 10px;
  height: 12px;
  position: absolute;
  top: 180px;
  left: 186px;
}

.web .vector-33 {
  width: 9px;
  height: 13px;
  position: absolute;
  top: 96px;
  left: 375px;
}

.web .vector-34 {
  width: 8px;
  height: 10px;
  position: absolute;
  top: 170px;
  left: 269px;
}

.web .vector-35 {
  width: 8px;
  height: 7px;
  position: absolute;
  top: 196px;
  left: 527px;
}

.web .vector-36 {
  width: 6px;
  height: 8px;
  position: absolute;
  top: 284px;
  left: 490px;
}

.web .vector-37 {
  width: 5px;
  height: 9px;
  position: absolute;
  top: 58px;
  left: 323px;
}

.web .vector-38 {
  width: 5px;
  height: 8px;
  position: absolute;
  top: 55px;
  left: 119px;
}

.web .vector-39 {
  width: 15px;
  height: 5px;
  position: absolute;
  top: 293px;
  left: 467px;
}

.web .vector-40 {
  width: 16px;
  height: 6px;
  position: absolute;
  top: 243px;
  left: 143px;
}

.web .vector-41 {
  width: 7px;
  height: 4px;
  position: absolute;
  top: 82px;
  left: 537px;
}

.web .group-25 {
  width: 51px;
  height: 59px;
  position: absolute;
  top: 131px;
  left: 266px;
}

.web .ellipse-wrapper {
  background-image: url("union.66f995ec.svg");
  background-size: 100% 100%;
  width: 101px;
  height: 109px;
  position: relative;
  top: -15px;
  left: -25px;
}

.web .ellipse-3 {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 18px;
  left: 28px;
}

.web .overlap-44 {
  width: 24px;
  height: 32px;
  position: absolute;
  top: 31px;
  left: 443px;
}

.web .vector-42 {
  width: 14px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.web .vector-43 {
  width: 10px;
  height: 12px;
  position: absolute;
  top: 19px;
  left: 14px;
}

.web .vector-44 {
  width: 9px;
  height: 12px;
  position: absolute;
  top: 90px;
  left: 123px;
}

.web .vector-45 {
  width: 11px;
  height: 13px;
  position: absolute;
  top: 50px;
  left: 107px;
}

.web .overlap-45 {
  width: 19px;
  height: 23px;
  position: absolute;
  top: 351px;
  left: 572px;
}

.web .vector-46 {
  width: 12px;
  height: 14px;
  position: absolute;
  top: 10px;
  left: 0;
}

.web .vector-47 {
  width: 7px;
  height: 11px;
  position: absolute;
  top: 0;
  left: 12px;
}

.web .vector-48 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 360px;
  left: 534px;
}

.web .vector-49 {
  width: 7px;
  height: 6px;
  position: absolute;
  top: 61px;
  left: 92px;
}

.web .CONTENT-wrapper {
  flex: none;
  align-items: center;
  gap: 141px;
  display: inline-flex;
  position: relative;
}

.web .CONTENT {
  width: 690px;
  height: 742px;
  position: relative;
  overflow: hidden;
}

.web .heading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 169px;
}

.web .contact-us {
  color: var(--surface-main);
  font-family: var(--headline-2-font-family);
  font-size: var(--headline-2-font-size);
  font-style: var(--headline-2-font-style);
  font-weight: var(--headline-2-font-weight);
  letter-spacing: var(--headline-2-letter-spacing);
  line-height: var(--headline-2-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.web .text-wrapper-14 {
  color: var(--secondary-2);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.web .form {
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  display: inline-flex;
  position: absolute;
  top: 140px;
  left: 0;
}

.web .div-3 {
  width: 694px;
  height: 88px;
  margin-right: -4px;
  position: relative;
}

.web .overlap-group-8 {
  background-color: var(--darkhover);
  border: 1px solid;
  border-color: var(--darkline-dark);
  border-radius: 8px;
  width: 690px;
  height: 48px;
  position: absolute;
  top: 40px;
  left: 0;
}

.web .text-wrapper-15 {
  color: var(--secondary);
  font-family: var(--body-02-font-family);
  font-size: var(--body-02-font-size);
  font-style: var(--body-02-font-style);
  font-weight: var(--body-02-font-weight);
  letter-spacing: var(--body-02-letter-spacing);
  line-height: var(--body-02-line-height);
  white-space: nowrap;
  position: absolute;
  top: 9px;
  left: 19px;
}

.web .text-wrapper-16 {
  color: var(--secondary-2);
  font-family: var(--body-01-bold-font-family);
  font-size: var(--body-01-bold-font-size);
  font-style: var(--body-01-bold-font-style);
  font-weight: var(--body-01-bold-font-weight);
  letter-spacing: var(--body-01-bold-letter-spacing);
  line-height: var(--body-01-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.web .message {
  width: 694px;
  height: 162px;
  margin-right: -4px;
  position: relative;
}

.web .overlap-46 {
  background-color: var(--darkhover);
  border: 1px solid;
  border-color: var(--darkline-dark);
  border-radius: 8px;
  width: 690px;
  height: 122px;
  position: absolute;
  top: 40px;
  left: 0;
}

.web .button-3 {
  all: unset;
  box-sizing: border-box;
  width: 692px;
  height: 52px;
  position: absolute;
  top: 574px;
  left: 0;
}

.web .overlap-47 {
  background-color: var(--primary);
  border-radius: 100px;
  width: 690px;
  height: 52px;
  position: relative;
}

.web .text-wrapper-17 {
  color: var(--surface-main);
  font-family: var(--button-default-font-family);
  font-size: var(--button-default-font-size);
  font-style: var(--button-default-font-style);
  font-weight: var(--button-default-font-weight);
  letter-spacing: var(--button-default-letter-spacing);
  line-height: var(--button-default-line-height);
  text-align: center;
  white-space: nowrap;
  height: 16px;
  position: absolute;
  top: 18px;
  left: 288px;
}

/*# sourceMappingURL=index.d504e634.css.map */
